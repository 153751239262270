import axios from 'axios';

export const apiSubscribe = (data) => new Promise ((resolve, reject) => {
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/subscribe`,
        data : data
    };

    axios(config)
        .then(function (response) {
            resolve(response.data);
        })
        .catch(function (error) {
            reject(error);
        });
})