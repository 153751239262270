// import firebase from 'firebase/app';
// import "firebase/firestore";
// import "firebase/auth";
import { initializeApp } from 'firebase/app';
import {getAuth} from "firebase/auth";

// var firebaseConfig = {
//     apiKey: "AIzaSyDIrl-qdNPVz_yC8us4Sw671ELjNba-Qt0",
//     authDomain: "nlnk-qa.firebaseapp.com",
//     projectId: "nlnk-qa",
//     storageBucket: "nlnk-qa.appspot.com",
//     messagingSenderId: "916089931877",
//     appId: "1:916089931877:web:495351ca5c3a2f4d371e8c"
// };

const firebaseConfig = {
    apiKey: "AIzaSyC6IWUAoFIW9ZbGUhRbbXCLQRjgj2DSvzk",
    authDomain: "neolnk-qa.firebaseapp.com",
    projectId: "neolnk-qa",
    storageBucket: "neolnk-qa.appspot.com",
    messagingSenderId: "220555131224",
    appId: "1:220555131224:web:e42988f177c1d786a5cd2d",
    measurementId: "G-T20GEDMQQG"
  };

const app= initializeApp(firebaseConfig);



//export const auth = firebase.auth();
export const auth = getAuth(app);
export default app;

