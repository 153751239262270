export const pieData = [
    {
      "id": "Germany",
      "label": "Germany",
      "value": 540,
      "color": "hsl(300, 70%, 50%)"
    },
    {
      "id": "India",
      "label": "India",
      "value": 437,
      "color": "hsl(51, 70%, 50%)"
    },
    {
      "id": "USA",
      "label": "USA",
      "value": 193,
      "color": "hsl(100, 70%, 50%)"
    },
    {
      "id": "Albenia",
      "label": "Albenia",
      "value": 193,
      "color": "hsl(100, 70%, 50%)"
    }
  ];