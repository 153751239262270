import axios from 'axios';

export const apiList = (idToken) => new Promise((resolve,reject) => {
    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/list`,
        headers: { 
            'Authorization': `Bearer ${idToken}`, 
            'Content-Type': 'application/json'
          },
      };
      
      axios(config)
        .then(async function (response) {
            resolve(response);
        })
        .catch(function (error) {
            reject(error);
        });
})