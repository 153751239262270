import axios from 'axios';

export const analyticsVisits = (idToken, short_url) => new Promise((resolve, reject) => {
    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/analytics-visits?url=${short_url}`,
        headers: { 
          'Authorization': `Bearer ${idToken}` 
        }
      };
      
      axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
});

export const analyticsCountries = (idToken, short_url) => new Promise((resolve, reject) => {
  var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/analytics-countries?url=${short_url}`,
      headers: { 
        'Authorization': `Bearer ${idToken}` 
      }
    };
    
    axios(config)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error);
    });
});

export const analyticsBrowsers = (idToken, short_url) => new Promise((resolve, reject) => {
  var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/analytics-browsers?url=${short_url}`,
      headers: { 
        'Authorization': `Bearer ${idToken}` 
      }
    };
    
    axios(config)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error);
    });
});