import { ResponsivePie } from '@nivo/pie';
import { pieData } from './PieChartData';

const MyResponsivePie = () => (
  <ResponsivePie
    data={pieData}
    margin={{ top: 40, right: 110, bottom: 40, left: 10 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={0}
    colors={{ scheme: "category10" }}
    borderWidth={1}
    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
    enableRadialLabels={false}
    // radialLabelsSkipAngle={10}
    // radialLabelsTextColor="#333333"
    // radialLabelsLinkColor={{ from: 'color' }}
    // sliceLabelsSkipAngle={10}
    // sliceLabelsTextColor="#333333"
    // defs={[
    //     {
    //         id: 'dots',
    //         type: 'patternDots',
    //         background: 'inherit',
    //         color: 'rgba(255, 255, 255, 0.3)',
    //         size: 4,
    //         padding: 1,
    //         stagger: true
    //     },
    //     {
    //         id: 'lines',
    //         type: 'patternLines',
    //         background: 'inherit',
    //         color: 'rgba(255, 255, 255, 0.3)',
    //         rotation: -45,
    //         lineWidth: 6,
    //         spacing: 10
    //     }
    // ]}
    // fill={[
    //     {
    //         match: {
    //             id: 'ruby'
    //         },
    //         id: 'dots'
    //     },
    //     {
    //         match: {
    //             id: 'c'
    //         },
    //         id: 'dots'
    //     },
    //     {
    //         match: {
    //             id: 'go'
    //         },
    //         id: 'dots'
    //     },
    //     {
    //         match: {
    //             id: 'python'
    //         },
    //         id: 'dots'
    //     },
    //     {
    //         match: {
    //             id: 'scala'
    //         },
    //         id: 'lines'
    //     },
    //     {
    //         match: {
    //             id: 'lisp'
    //         },
    //         id: 'lines'
    //     },
    //     {
    //         match: {
    //             id: 'elixir'
    //         },
    //         id: 'lines'
    //     },
    //     {
    //         match: {
    //             id: 'javascript'
    //         },
    //         id: 'lines'
    //     }
    // ]}
    legends={[
      {
        anchor: "right",
        direction: "column",
        justify: false,
        translateX: 250,
        translateY: 0,
        itemsSpacing: 10,
        itemWidth: 250,
        itemHeight: 18,
        itemTextColor: "#999",
        itemDirection: "left-to-right",
        itemOpacity: 1,
        symbolSize: 12,
        symbolShape: "square",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000",
            },
          },
        ],
      },
    ]}
  />
);

export default MyResponsivePie;