import React, {useState} from 'react';
import { makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
import { Button, Paper } from '@mui/material';
import validator from 'validator';
import { apiUrl } from '../../Utility/Apis/apiUrl';
import theme from "./../../theme";

const customStyle = {
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 450,
    borderRadius: 20,
    
  },
  input: {
    marginLeft: theme.spacing(5),
    flex: 1,
    fontSize: 14
  },
  icon: {
    padding: 10,
    color: theme.palette.primary.main,
  },
  searchButton: {
    background: theme.palette.primary.main,
    borderRadius: 20,
    textTransform: "none",
    color: '#fff',
    backgroundColor: ' #78B326',
    '&:hover': {
        background: ' #78B326',
    },
    fontSize: 16,
    width: 100
  },
};

export default function CustomizedInputBase(props) {
//  const classes = useStyles();

  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState(false);

  const handleShorten = async () => {
    if (validator.isURL(url)) {
      try {
        const {idToken} = await props.getUserData();  
        var data = JSON.stringify({"url" : url}); 
        // var config = {
        //   method: 'post',
        //   url: 'https://223fad39af65.ngrok.io/api/url',
        //   headers: { 
        //     'Authorization': `Bearer ${idToken}`, 
        //     'Content-Type': 'application/json'
        //   },
        //   data : data
        // };
        console.log(data);
      console.log("idToken:", idToken);
        apiUrl(data, idToken)
        .then(function (response) {
          console.log(response);
           props.getUserLinks();
          
        })
        .catch(function (error) {
          console.log(error);
        });  
      } catch {

      }          
     
    } else {
        setUrlError(true);       
    }
}

  return (
    <Paper component="form" className="ShortenUrl_root" sx={customStyle.root}>
      <InputBase
        className="ShortenUrl_input"
        sx={customStyle.input}
        placeholder="Shorten your URL"
        onChange={(e) => {
          setUrl(e.target.value);
          if (validator.isURL(e.target.value) && urlError) {
            setUrlError(false);
          }
        }}
        error={urlError}
        value={url}
        helper={urlError ? "Enter a valid url" : ""}
      />
      <Button
        className="ShortenUrl_searchButton"
        sx={customStyle.searchButton}
        onClick={handleShorten}
      >
        Shorten
      </Button>
    </Paper>
  );
}