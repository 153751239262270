import React, {useState} from 'react';
import Grid from '@mui/material/Grid';

import { Card, TextField, Typography, Button, Box, CircularProgress } from '@mui/material';
import Link from '@mui/material/Link';
import { ReactComponent as HomePageLogo } from '../../assets/HomePageLogo.svg';
import { ReactComponent as DescriptionImage } from '../../assets/DescriptionImage.svg';
import { ReactComponent as CarbonLogoSlackImage } from '../../assets/CarbonLogoSlackImage.svg';
import { ReactComponent as CarbonLogoVMWareImage } from '../../assets/CarbonLogoVMWareImage.svg';
import { ReactComponent as CarbonLogoDiggImage } from '../../assets/CarbonLogoDiggImage.svg';
import { ReactComponent as IonLogoAmazonImage } from '../../assets/IonLogoAmazonImage.svg';
import { ReactComponent as FooterLogo } from '../../assets/FooterLogo.svg';
import { ReactComponent as TwitterLogo } from '../../assets/TwitterLogo.svg';
import { ReactComponent as InstagramLogo } from '../../assets/InstagramLogo.svg';
import { ReactComponent as FacebookLogo } from '../../assets/FacebookLogo.svg';
import Recaptcha from 'react-google-invisible-recaptcha';
import { t } from 'typy';
import validator from 'validator';
import FormData from 'form-data';
import { apiUrl } from '../../Utility/Apis/apiUrl';
import { useMediaQuery } from "@mui/material";
import { apiSubscribe } from '../../Utility/Apis/apiSubscribe';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";



import theme from "./../../theme.js";

const customStyle = {
  root: {
    borderBottomRightRadius: { xs: 45, sm: 45, md: 90 },
    borderBottomLeftRadius: { xs: 45, sm: 45, md: 90 },
    background: "linear-gradient(116.38deg, #578F08 27.13%, #78B326 102.01%)",
    padding: {
      xs: "40px 40px",
      sm: "40px 40px",
      md: "40px 80px",
      lg: "40px 80px",
    },
    position: "relative",
    justifyContent: { xs: "center", sm: "center", md: "center" },
  },
  rootURL: {
    position: "absolute",
    display: "flex",
    justifyContent: { xs: "center", sm: "center", md: "center" },
    bottom: { xs: -60, sm: -60, md: -70, lg: -70 },
    width: { xs: "80%", sm: "80%", md: "80%", lg: "100%" },
  },
  URL: {
    justifyContent: "center",
    background: "#fff",
    width: { xs: "80%", sm: "80%", md: "80%", lg: "60%" },
    padding: "16px",
    borderRadius: "20px",
    boxShadow: "0px 9px 17px rgba(0, 0, 0, 0.1)",
  },
  rootImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: { xs: 30, sm: 30, md: 60, lg: 60 },
    color: "#FFFFFF",
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
  },
  text: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: { xs: "10px", sm: "10px", md: "20px", lg: "20px" },
    lineHeight: "116%",
    color: "#FFFFFF",
    opacity: 0.8,
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
  },
  spacing: {
    padding: "2rem 0 2rem",
  },
  input: {
    fontSize: { sm: "14px" },
  },
  button: {
    color: "#000000",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignIitems: "center",
    padding: "5px 50px",
    background: "#FFFFFF",
    borderRadius: "8px",
    border: "0px solid #fff",
    textTransform: "none",
  },
  buttonShorten: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 20px",
    background: "#78B326",
    color: "#FFF",
    borderRadius: "8px",
    textTransform: "none",
    height: "40px",
    width: "150px",
    border: "0px solid #fff",
  },
  description: {
    paddingLeft: "120px",
    padding: { xs: "20px", sm: "20px", md: "20px" },
  },
  descriptionTitle: {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "116.7%",
    color: "#78B326",
    padding: "20px 0px 20px",
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
    fontSize: { xs: 24, sm: 24, md: 24, lg: "36px" },
  },
  descriptionText: {
    textAlign: "center",
    color: "#898989",
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
    fontSize: { xs: 14, sm: 14, md: 14, lg: "18px" },
  },
  descriptionBottomTitle: {
    textAlign: "center",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "116.7%",
    color: "#78B326",
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
    fontSize: { xs: 24, sm: 24, md: 24, lg: "36px" },
  },
  descriptionBottomText: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "116.7%",
    textAlign: "center",
    color: "#898989",
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
    fontSize: { xs: 14, sm: 14, md: 14, lg: "18px" },
    padding: { xs: "20px", sm: "20px", md: "20px", lg: "0 230px" },
  },
  features: {
    display: { xs: "block", sm: "block", md: "flex" },
    justifyContent: "center",
    alignItems: "center",
    margin: { xs: "0px", sm: "0px", md: "50px" },
    padding: { xs: "0px", sm: "0px", md: "50px" },
  },
  featuresCard: {
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.05)",
    padding: "30px",
    margin: { xs: "20px", sm: "20px" },
    borderRadius: { xs: "25px", sm: "25px", md: "0 20px", lg: "35px" },
  },
  featuresImage: {
    // padding: '0px 0px 0px 50px',
    marginLeft: { xs: "55px", sm: "55px" , md: "50px" },
  },
  featuresTitle: {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "116.7%",
    textAlign: "center",
    color: "#78B326",
    padding: "45px 0px 30px",
  },
  featuresText: {
    textAlign: "center",
  },
  pressTitle: {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "bold",
    // fontSize: "36px",
    lineHeight: "116.7%",
    textAlign: "center",
    color: "#78B326",
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
    fontSize: { xs: 26, sm: 26, md: 26, lg: 36 },
    marginTop: { xs: "60px", sm: "60px", md: "60px" },
  },
  pressText: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: { sm: 14, md: "18px" },
    padding: { sm: "20px", md: "30px 250px 50px" },
    textAlign: "center",
    lineHeight: "116.7%",
    color: "#898989",
    display: { xs: "flex", sm: "flex", md: "flex" },
    justifyContent: { xs: "center", sm: "center", md: "center" },
  },
  pricing: {
    backgroundColor: "#78B326",
    padding: { sm: "35px", md: "50px" },
    marginTop: { sm: "45px" },
    width: "100%",
  },
  pricingTitle: {
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "116.7%",
    fontSize: { sm: "26px", md: "30px" },
    textAlign: "center",
    color: "#FFFFFF",
  },
  pricingButton: {
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "34px",
    color: "#78B326",
    backgroundColor: "#fff",
    flex: "none",
    padding: { sm: "8px 24px", md: "12px 39px" },
    margin: "0px 14px",
    borderRadius: "41px",
    border: "0px solid #fff",
    textTransform: "none",
  },
  newsletter: {
    padding: { sm: "0px", md: "100px" },
    marginTop: { sm: "35px" },
  },
  newsTitle: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: { sm: "26px", md: "30px", lg: "36px" },
    lineHeight: "116.7%",
    textalign: "center",
    color: "#78B326",
    paddingBottom: "30px",
  },
  newsButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    variant: "contained",
    padding: "15px 60px",
    background: "#78B326",
    borderRadius: "8px",
    height: "42px",
    width: "90px",
    border: "0px solid #fff",
    textTransform: "none",
  },
  newsletterForm: {
    display: "flex",
    margin: { sm: "0px 10px", md: "0px 120px" },
  },
  newsInput: {
    fontSize: { sm: 12 },
  },
  newsFooter: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: { sm: "18px", md: "14px" },
    lineHeight: "19px",
    color: "#898989",
  },
  links: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: { xs: "8px", sm: "8px", md: "14px" },
    lineHeight: "19px",
    textAlign: "center",
    color: "#898989",
  },
  footer: {
    display: "flex",
    width: "100%",
    justifyContent: { sm: "10px", md: "space-between" },
    backgroundColor: "#333333",
    padding: { sm: "10px", md: "32px 100px" },
  },
  footerLogo: {
    height: "37px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
    margin: { sm: "20px" },
  },
  footerText: {
    textAlign: "start",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "23px",
    color: "#FFFFFF",
    opacity: "0.7",
  },
  footerLinks: {
    display: { sm: "flex" },
    justifyContent: { sm: "space-between" },
  },
};

const HomePage = (props) => {

   

    let recaptcha = null;
    let emailRecaptcha = null;

    const [url, setUrl] = useState('');
    const [urlError, setUrlError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [shortURL, setShortURL] = useState("");
   
    const [open,setOpen] = useState(false);
    const [urlLoading, setUrlLoading] = useState(false);


  
const handleCopy = ()=>{

  navigator.clipboard.writeText(shortURL);
  alert("URL Copied");
}

const handleDialogClose = () => {
  setShortURL("");
  setOpen(false);
};

    //  const isMobile = () => {
    //     if (props.width === 'sm' || props.width === 'xs') {
          
    //        return true;
    //      }
    //      return false;
    //    }

    //const theme = useTheme();

    const isMobile = useMediaQuery(
      theme.breakpoints.down("md") ||
        theme.breakpoints.down("sm") ||
        theme.breakpoints.down("xs")
    );

    const handleShorten = () => {
        if (validator.isURL(url)) {            
            if (!t(recaptcha).isNullOrUndefined) {
                try {
                    localStorage.setItem('url', url);
                    recaptcha.execute();
                } catch {
                    localStorage.removeItem('url');
                }
            } else {
                console.log('errorRecaptcha :', recaptcha);
            }
        } else {
            setUrlError(true);     
            setUrlLoading(false);  
        }
    }

    const handleSubscribe = () => {
        if (validator.isEmail(email)) {            
            if (!t(emailRecaptcha).isNullOrUndefined) {
                emailRecaptcha.execute();
                console.log('emailRecaptcha :', emailRecaptcha);
            } else {
                console.log('emailRecaptcha :', emailRecaptcha);
            }
        } else {
            setEmailError(true);
        }
    }

    const handleUrlRecaptchaResolved = async (token) => {
      
        const preservedUrl = localStorage.getItem('url');
        console.log('preservedUrl :', preservedUrl);
        var data = new FormData();
        data.append('url', preservedUrl);
        data.append('g-recaptcha-response', token);
        

        // var config = {
        // method: 'post',
        // url: 'https://223fad39af65.ngrok.io/api/url',
        // data : data
        // };

        // axios(config)
        // .then(function (response) {
        //     console.log('urlResponse: ',response.data);
        //     localStorage.removeItem('url');
        // })
        // .catch(function (error) {
        //     console.log(error);
        //     localStorage.removeItem('url');
        // });
          
        apiUrl(data)
            .then(response => {
                console.log('urlResponse: ',response);
                 console.log("ShortURL: ", response.data.short_url);
                setShortURL(response.data.short_url);
                setUrlLoading(false);
                localStorage.removeItem('url');
            })
            .catch(error => {
                console.log(error);
                localStorage.removeItem('url');
            })

    }

    const handleEmailRecaptchaResolved = (token) => {
        var data = new FormData();
        data.append('email', email);
        data.append('g-recaptcha-response', token);

        // var config = {
        //     method: 'post',
        //     url: 'https://223fad39af65.ngrok.io/api/subscribe',
        //     // headers: { 
        //     //     ...data.getHeaders()
        //     // },
        //     data : data
        // };

        // axios(config)
        //     .then(function (response) {
        //         console.log(response.data);
        //     })
        //     .catch(function (error) {
        //         console.log(error.message);
        //     });

        apiSubscribe(data)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.message);
            })
    }

   


    return (
      <>
        {/* Title */}

        <Grid
          container
          spacing={"12px"}
          className="Home_root"
          sx={customStyle.root}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isMobile ? (
              <Grid item>
                <Typography
                  className="Home_title"
                  sx={customStyle.title}
                  style={{ textAlign: "left" }}
                >
                  Lorem ipsum dolor sit amet
                </Typography>
                <Typography
                  variant="h8"
                  className="Home_text"
                  sx={customStyle.text}
                >
                  Etiam laoreet viverra purus ac euismod. Donec quis porta mi.
                  In semper, lacus id lobortis egestas.
                </Typography>
                <Box className="Home_spacing" sx={customStyle.spacing}>
                  <Button className="Home_button" sx={customStyle.button}>
                    Get Started Free
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={6}>
                <Typography
                  className="Home_title"
                  sx={customStyle.title}
                  style={{ textAlign: "left" }}
                >
                  Lorem ipsum dolor sit amet
                </Typography>
                <Typography
                  variant="h8"
                  className="Home_text"
                  sx={customStyle.text}
                >
                  Etiam laoreet viverra purus ac euismod. Donec quis porta mi.
                  In semper, lacus id lobortis egestas.
                </Typography>
                <Box item className="Home_spacing" sx={customStyle.spacing}>
                  <Button className="Home_button" sx={customStyle.button}>
                    Get Started Free
                  </Button>
                </Box>
              </Grid>
            )}

            {!isMobile ? (
              <Grid
                item
                className="Home_rootImage"
                xs={6}
                sx={customStyle.rootImage}
              >
                <HomePageLogo
                  style={{
                    height: 350,
                    padding: "0 -1 100 -3",
                    margin: "0px 0px 30px",
                  }}
                />
              </Grid>
            ) : null}
          </div>
          <Grid className="Home_rootURL" sx={customStyle.rootURL}>
            <Grid className="Home_rootURL" sx={customStyle.URL}>
              <div style={{ display: "flex", padding: "16px" }}>
                <TextField
                  fullWidth
                  className="Home_input"
                  type="text"
                  placeholder="Shorten your URL"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    setUrl(e.target.value);
                    if (validator.isURL(e.target.value) && urlError) {
                      
                      setOpen(false);
                      setUrlError(false);
                      
                    }
                  }}
                  error={urlError}
                  value={url}
                  helperText={urlError ? "A valid URL is required" : ""}
                />
                <div style={{ width: 16 }} />
                <Button
                  className="Home_buttonShorten"
                  sx={customStyle.buttonShorten}
                  onClick={() => {
                    setUrlLoading(true);
                    setOpen(true);
                    handleShorten();
                    
                  }}
                >
                  Shorten
                </Button>
                {!urlLoading ? (
                  <Dialog
                    open={open && !urlLoading && !urlError}
                    onClose={handleDialogClose}
                    aria-labelledby="customized-dialog-title"
                    sx={{ alignItems: "center" }}
                  >
                    <DialogContent sx={{ alignItems: "center" }}>
                      <DialogContentText>{shortURL}</DialogContentText>
                    </DialogContent>
                    <DialogActions
                      autoFocus
                      onClick={() => {
                        handleCopy();
                        handleDialogClose();
                      }}
                      sx={{
                        justifyContent: "center",
                        padding: "5px 5px",
                        background: "#78B326",
                      }}
                    >
                      Copy URL
                    </DialogActions>
                  </Dialog>
                ) : (
                  <CircularProgress style={{alignItems: "center" , padding: "5px"}}/>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  margin: "2px 0px 0px 0px",
                }}
              >
                <Typography className="Home_links" sx={customStyle.links}>
                  By creating an account, you are agreeing to nlnk’s
                  <Link
                    href="#"
                    style={{ color: "#78B326", paddingLeft: "4px" }}
                  >
                    Terms of service
                  </Link>
                  <span style={{ padding: "0px 2px" }}> & </span>
                  <Link href="#" style={{ color: "#78B326" }}>
                    Privacy Policy
                  </Link>
                </Typography>
              </div>
              {/* </form> */}
            </Grid>
          </Grid>
        </Grid>

        {/* Description */}

        <div>
          <Grid
            container
            spacing={3}
            style={isMobile ? { marginTop: "100px" } : { marginTop: "279px" }}
          >
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid className="Home_description" sx={customStyle.description}>
                <Typography
                  className="Home_descriptionTitle"
                  sx={customStyle.descriptionTitle}
                >
                  New Standard for link shorting
                </Typography>
                <Typography
                  className="Home_descriptionText"
                  sx={customStyle.descriptionText}
                >
                  Etiam laoreet viverra purus ac euismod. Donec quis porta mi.
                  In semper, lacus id lobortis egestas, metus lacus luctus
                  massa, in dignissim dui leo id quam. Etiam laoreet viverra
                  purus ac euismod. Donec quis porta mi. In semper, lacus id
                  lobortis egestas.
                </Typography>
              </Grid>
            </Grid>
            {isMobile ? null : (
              <Grid
                item
                xs={6}
                className="Home_rootImage"
                sx={customStyle.rootImage}
              >
                <DescriptionImage
                  style={{ height: 250, padding: "0 -1 0 -3" }}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <div style={{ margin: "50px" }}>
          <Typography
            className="Home_descriptionBottomTitle"
            sx={customStyle.descriptionBottomTitle}
          >
            Lorem ipsum dolor sit amet.
          </Typography>
          <Typography
            className="Home_descriptionBottomText"
            sx={customStyle.descriptionBottomText}
          >
            Etiam laoreet viverra purus ac euismod. Donec quis porta mi. In
            semper, lacus id lobortis egestas, metus lacus luctus massa, in
            dignissim dui leo id quam.
          </Typography>
        </div>

        {/* Features/ */}

        <Box className="Home_features" sx={customStyle.features}>
          <Card className="Home_featuresCard" sx={customStyle.featuresCard}>
            <CarbonLogoSlackImage
              className="Home_featuresImage"
              sx={customStyle.featuresImage}
            />
            <Typography
              className="Home_featuresTitle"
              sx={customStyle.featuresTitle}
            >
              Lorem ipsum
            </Typography>
            <Typography
              className="Home_featuresText"
              sx={customStyle.featuresText}
            >
              Etiam laoreet viverra purus ac euismod. Donec quis porta mi. In
              semper, lacus id lobortis egestas, metus lacus luctus massa, in
              dignissim dui leo id quam.
            </Typography>
          </Card>
          <Card className="Home_featuresCard" sx={customStyle.featuresCard}>
            <CarbonLogoSlackImage
              className="Home_featuresImage"
              sx={customStyle.featuresImage}
            />
            <Typography
              className="Home_featuresTitle"
              sx={customStyle.featuresTitle}
            >
              Lorem ipsum
            </Typography>
            <Typography
              className="Home_featuresText"
              sx={customStyle.featuresText}
            >
              Etiam laoreet viverra purus ac euismod. Donec quis porta mi. In
              semper, lacus id lobortis egestas, metus lacus luctus massa, in
              dignissim dui leo id quam.
            </Typography>
          </Card>
          <Card className="Home_featuresCard" sx={customStyle.featuresCard}>
            <CarbonLogoSlackImage
              className="Home_featuresImage"
              sx={customStyle.featuresImage}
            />
            <Typography
              className="Home_featuresTitle"
              sx={customStyle.featuresTitle}
            >
              Lorem ipsum
            </Typography>
            <Typography
              className="Home_featuresText"
              sx={customStyle.featuresText}
            >
              Etiam laoreet viverra purus ac euismod. Donec quis porta mi. In
              semper, lacus id lobortis egestas, metus lacus luctus massa, in
              dignissim dui leo id quam.
            </Typography>
          </Card>
        </Box>

        {/* Press */}

        <div
          style={{ diplay: "flex", justifyContent: "center", width: "100%" }}
        >
          <div>
            <Typography className="Home_pressTitle" sx={customStyle.pressTitle}>
              Global brands that loves us.
            </Typography>
            <Typography className="Home_pressText" sx={customStyle.pressText}>
              Etiam laoreet viverra purus ac euismod. Donec quis porta mi. In
              semper, lacus id lobortis egestas, metus lacus luctus massa, in
              dignissim dui leo id quam.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CarbonLogoVMWareImage
              style={
                isMobile ? { marginRight: "12px" } : { marginRight: "32px" }
              }
            />
            <CarbonLogoDiggImage
              style={
                isMobile ? { marginRight: "12px" } : { marginRight: "32px" }
              }
            />
            <IonLogoAmazonImage
              style={
                isMobile ? { marginRight: "12px" } : { marginRight: "32px" }
              }
            />
          </div>
        </div>

        {/* Pricing */}

        <Grid className="Home_pricing" sx={customStyle.pricing}>
          <Typography
            className="Home_pricingTitle"
            sx={customStyle.pricingTitle}
          >
            More than a link Shortner
          </Typography>
          <div style={{ height: "12px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 10px 10px",
            }}
          >
            <Button
              className="Home_pricingButton"
              sx={customStyle.pricingButton}
            >
              <Typography
                style={isMobile ? { fontSize: "14px" } : { fontSize: "20px" }}
              >
                Get Started For Free
              </Typography>
            </Button>
          </div>
        </Grid>

        {/* Newsletter */}

        <Grid className="Home_newsletter" sx={customStyle.newsletter}>
          <Typography className="Home_newsTitle" sx={customStyle.newsTitle}>
            Subscribe to our newsletter
          </Typography>
          <Card className="Home_newsletterForm" sx={customStyle.newsletterForm}>
            <div
              style={{ display: "flex", width: "100%", padding: "16px 20px" }}
            >
              <TextField
                fullWidth
                className="Home_newsInput"
                sx={customStyle.newsInput}
                type="text"
                placeholder="Enter your email address"
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (validator.isEmail(e.target.value) && emailError) {
                    setEmailError(false);
                  }
                }}
                error={emailError}
                value={email}
                helperText={
                  emailError ? "A valid Email address is required" : ""
                }
              />
              <div style={{ width: 24 }} />
              <Button
                className="Home_newsButton"
                sx={customStyle.newsButton}
                style={{ color: "#FFFFFF" }}
                onClick={handleSubscribe}
              >
                Submit
              </Button>
            </div>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              padding: "30px 0px",
            }}
          >
            <Typography className="Home_links" sx={customStyle.links}>
              By creating an account, you are agreeing to nlnk’s
              <Link href="#" style={{ color: "#78B326", paddingLeft: "4px" }}>
                Terms of service
              </Link>
              <span style={{ padding: "0px 2px" }}> & </span>
              <Link href="#" style={{ color: "#78B326" }}>
                Privacy Policy
              </Link>
            </Typography>
          </div>
        </Grid>

        {/* Footer */}

        <Grid container className="Home_footer" sx={customStyle.footer}>
          <Grid item xs={isMobile ? 12 : 6}>
            <FooterLogo
              className="Home_footerLogo"
              sx={customStyle.footerLogo}
              style={{
                height: "37px",
                display: "flex",
                alignItems: "flex-start",
                margin: "0 0 20px 0",
              }}
            />
            <div
              style={
                isMobile ? { paddingRight: "10px" } : { paddingRight: "100px" }
              }
            >
              <Typography
                className="Home_footerText"
                sx={customStyle.footerText}
                style={{
                  textAlign: "start",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "23px",
                  color: "#FFFFFF",
                  opacity: "0.7",
                }}
              >
                Etiam laoreet viverra purus ac euismod. Donec quis porta mi. In
                semper, lacus id lobortis egestas, metus lacus luctus.
              </Typography>
              {!isMobile ? (
                <div style={{ display: "flex", padding: "16px 0px" }}>
                  <TwitterLogo style={{ marginRight: 16 }} />
                  <InstagramLogo style={{ marginRight: 18 }} />
                  <FacebookLogo />
                </div>
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 2}
            style={
              isMobile
                ? { display: "flex", justifyContent: "space-between" }
                : null
            }
          >
            <div
              style={isMobile ? { padding: "20px" } : { paddingLeft: "130px" }}
            >
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  paddingBottom: "10px",
                }}
              >
                Links
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 1
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 2
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 3
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 4
              </Typography>
            </div>
          </Grid>
          <Grid item xs={isMobile ? 12 : 2}>
            <div
              style={isMobile ? { padding: "20px" } : { paddingLeft: "75px" }}
            >
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  paddingBottom: "10px",
                }}
              >
                Links
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 1
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 2
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 3
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 4
              </Typography>
            </div>
          </Grid>
          <Grid item xs={isMobile ? 12 : 2}>
            <div
              style={isMobile ? { padding: "20px" } : { paddingLeft: "25px" }}
            >
              <Typography
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  paddingBottom: "10px",
                }}
              >
                Links
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 1
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 2
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 3
              </Typography>
              <Typography
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "rgba(255, 255, 255, 0.7)",
                  paddingBottom: "10px",
                }}
              >
                Link 4
              </Typography>
            </div>
          </Grid>
          {isMobile ? (
            <div style={{ display: "flex", padding: "16px 0px" }}>
              <TwitterLogo style={{ marginRight: 16 }} />
              <InstagramLogo style={{ marginRight: 18 }} />
              <FacebookLogo style={{ marginRight: 16 }} />
            </div>
          ) : null}
        </Grid>
        <div style={{ backgroundColor: "#202020" }}>
          <Typography
            style={{
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "22px",
              textAlign: "center",
              color: "#FFFFFF",
              opacity: "0.7",
            }}
          >
            © 2021 nlnk, designed by <a href="https://neophyte.in">Neophyte Technologies LLP</a>
          </Typography>
        </div>
        <Recaptcha
          ref={(ref) => (recaptcha = ref)}
          sitekey="6LcTTEAbAAAAAHHDFgJ6THuveGw272LMDy-3SGd6"
          onResolved={(res) => {
            console.log("response :", res);
            if (!t(res).isNullOrUndefined) {
              handleUrlRecaptchaResolved(res);
            } else {
              console.log("url recaptcha is undefined", emailRecaptcha);
              localStorage.removeItem("url");
            }
          }}
          onError={() => localStorage.removeItem("url")}
          onExpired={() => {
            if (!t(recaptcha).isNullOrUndefined) {
              recaptcha.reset();
            } else {
              console.log("recaptcha is undefined");
            }
          }}
        />
        <Recaptcha
          ref={(ref) => (emailRecaptcha = ref)}
          sitekey="6LcTTEAbAAAAAHHDFgJ6THuveGw272LMDy-3SGd6"
          onResolved={(res) => {
            console.log("response :", res);
            if (!t(res).isNullOrUndefined) {
              handleEmailRecaptchaResolved(res);
            } else {
              console.log("email recaptcha is undefined", emailRecaptcha);
            }
          }}
          onError={() => console.log("error")}
          onExpired={() => {
            if (!t(emailRecaptcha).isNullOrUndefined) {
              emailRecaptcha.reset();
            } else {
              console.log("emailRecaptcha is undefined");
            }
          }}
        />
      </>
    );
}

export default (HomePage);
