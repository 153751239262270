import { useEffect, useState } from 'react';
import HomePage from './Components/Homepage/HomePage';
import { Route, Switch, withRouter } from "react-router-dom";
import SignUp from './Components/SignUp/SignUp';
import NavBar from './Components/NavBar/NavBar';
import LogIn from './Components/LogIn/LogIn';
import Admin from './Components/Admin/Admin';
import { FirebaseApp } from 'firebase/app';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  getIdToken,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
} from "firebase/auth";

import {
  UserAuthContextProvider,
  
} from "./Container/Authentication/Auth";

const useStyles = makeStyles(() => ({
  loader: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function App(props) {
  const classes = useStyles();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [additionalInfo, setAdditionalInfo] = useState(null);
  //const { currentUser } = useUserAuth();

  const getUserData = () =>
    new Promise((resolve, reject) => {
      try {
        const auth = getAuth();
        const { currentUser } = auth;
        getIdToken(currentUser, true)
          .then((idToken) => {
            console.log({ success: true, idToken: idToken });
            resolve({ success: true, idToken: idToken });
          })
          .catch((idTokenError) => {
            console.log({
              success: false,
              errorMessage: idTokenError.message,
            });
            reject({ success: false, errorMessage: idTokenError.message });
          });
      } catch (e) {
        console.log({ success: false, errorMessage: e.message });
        reject({ success: false, errorMessage: e.message });
      }
    });

   const handleAuthentication = async () => {
     console.log("getting here");
     const auth = getAuth();
  const { currentUser } = auth;
    if (currentUser !== null) {
      const userResponse = await getUserData();
      console.log("idToken: ", userResponse.idToken);
    } else {
      const auth = getAuth();
      getRedirectResult(auth)
        .then(async (result) => {
          var googleUser = result.user;
          if (googleUser !== null) {
            const userResponse = await getUserData();
            console.log("getuserdetails:", userResponse);
            if (userResponse.success) {
              console.log("idToken: ", userResponse.idToken);
            }
            const data = {
              name: googleUser.displayName,
              email: googleUser.email,
              emailVerified: googleUser.emailVerified,
              phoneNumber: googleUser.phoneNumber,
              photoURL: googleUser.photoURL,
              refreshToken: googleUser.refreshToken,
            };
            setUser(data);

            props.history.push("/admin");
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.message);
        });
    }
   };

  useEffect(() => {
    console.log("UseEffect");
     handleAuthentication();
   // setLoading(false);
    
  }, []);

  const handleLogin = (loggedinUser, info) => {
    handleAuthentication();

    const data = {
      name: loggedinUser.displayName,
      email: loggedinUser.email,
      emailVerified: loggedinUser.emailVerified,
      phoneNumber: loggedinUser.phoneNumber,
      photoURL: loggedinUser.photoURL,
      refreshToken: loggedinUser.refreshToken,
    };
    setUser(data);
    setAdditionalInfo(info);

    props.history.push("/admin");
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Navigation Bar */}
          {window.location.pathname === "/admin" ? null : <NavBar />}
          <UserAuthContextProvider>
            <Switch>
              <Route
                path="/"
                exact
                render={() => <HomePage getUserData={getUserData} />}
              />
              <Route
                path="/signup"
                exact
                render={() => (
                  <SignUp
                    setLoading={setLoading}
                    handleLogin={handleLogin}
                    getUserData={getUserData}
                  />
                )}
              />
              <Route
                path="/login"
                exact
                render={() => (
                  <LogIn setLoading={setLoading} handleLogin={handleLogin} />
                )}
              />
              <Route
                path="/admin"
                exact
                render={() => (
                  <Admin
                    user={user}
                    loading={loading}
                    setLoading={setLoading}
                    getUserData={getUserData}
                  />
                )}
              />
            </Switch>
          </UserAuthContextProvider>
        </>
      )}
    </div>
  );
}

export default withRouter(App);
