export const lineData = [
    {
    //   "id": "japan",
    //   "color": "hsl(47, 70%, 50%)",
      "data": [
        {
          "x": "jan",
          "y": 270
        },
        {
          "x": "feb",
          "y": 12
        },
        {
          "x": "march",
          "y": 69
        },
        {
          "x": "april",
          "y": 172
        },
        {
          "x": "may",
          "y": 40
        },
        {
          "x": "june",
          "y": 293
        },
        {
          "x": "july",
          "y": 214
        },
        {
          "x": "aug",
          "y": 173
        },
        {
          "x": "sept",
          "y": 73
        },
        {
          "x": "oct",
          "y": 241
        },
        {
          "x": "nov",
          "y": 83
        },
        {
          "x": "dec",
          "y": 165
        }
      ]
    }
  ];