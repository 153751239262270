export const barData = [
    {
      "country": "Chrome",
      "browsing": 174,
      "browsingColor": "hsl(37, 70%, 50%)",
    },
    {
      "country": "Firefox",
      "browsing": 195,
      "browsingColor": "hsl(312, 70%, 50%)",
    },
    {
      "country": "Opera",
      "browsing": 62,
      "browsingColor": "hsl(315, 70%, 50%)",
    },
    {
      "country": "Edge",
      "browsing": 6,
      "browsingColor": "hsl(43, 70%, 50%)",
    },
    {
      "country": "Safari",
      "browsing": 22,
      "browsingColor": "hsl(97, 70%, 50%)",
    }    
  ];