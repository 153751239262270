import React from 'react';
import { useState } from 'react'
import { getIdToken } from 'firebase/auth';
import { Card, TextField, Typography, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FcGoogle } from 'react-icons/fc';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import { useUserAuth } from "../../Container/Authentication/Auth";
import { apiGetUserDetails } from '../../Utility/Apis/apiGetUserDetails';
import { GoogleAuthProvider, signInWithRedirect, getAuth } from 'firebase/auth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert   from '@mui/material/Alert';



const customStyles = {
  root: {
    width: "100%",
    height: "86vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 21px 4px rgba(0, 0, 0, 0.08)",
    padding: { sm: "18px 8px", md: "10px 10px 10px 10px" },
    position: "absolute",
    overflowX: "disabled",
    paddingBottom: "10px",
  },
  title: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    // fontSize: "30px",
    lineHeight: "116%",
    textAlign: "center",
    color: "#333333",
    padding: "30px 40px 12px",
    fontSize: { sm: "24px", md: "30px" },
    opacity: 0.9,
  },
  text: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    //s fontSize: "16px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#898989",
    background: "#FFFFFF",
    paddingBottom: "15px",
    fontSize: { sm: "12px", md: "16px" },
    opacity: 0.8,
  },
  
  link: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "19px",
    color: "#898989",
    paddingBottom: 10,
    opacity: 0.8,
  },
  linkContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 20px 0px",
    background: "#fff",
    opacity: 0.8,
  },
  DividerContainer: {
    padding: "8px 0px",
    textAlign: "center",
    opacity: 0.8,
  },
  Divider: {
    width: "90%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    opacity: 0.8,
  },
  LeftHr: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%",
    float: "left",
    opacity: 0.8,
  },
  RightHr: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%",
    float: "right",
    opacity: 0.8,
  },
  input: {
    display: "flex",
    alignItems: "flex-start",
    padding: "2px 20px 0px",
    background: "#FFFFFF",
    opacity: 0.8,
  },
  button: {
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 60px",
    border: "0px solid #fff",
    height: "40px",
  },
  logincard: {
    borderRadius: "4px",
    border: "1px ",
    backgroundColor: "#FFF",
  },

  buttonText: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#FFFFFF",
    // fontSize: "20px",
    lineHeight: "33px",
    fontSize: { sm: "14px", md: "20px" },
    opacity: 0.8,
    //  [theme.breakpoints.down('sm')]: {
    //      fontSize: '14px'
    //  }
  },
};

const LogIn = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const [errorMsg,setErrorMsg]=  useState("");
    const { login } = useUserAuth();
    //Email-password Authentication

    const [form, setForm] = useState({
      email: "",
      password: "",
    });


const [open, setOpen] = React.useState(false);
const vertical ="bottom";
const horizontal = "center";

  const handleClose = () => {
    
    setOpen(false);
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
       
        await login(form.email,form.password)
        .then( async(userObj) => {
        const loggedinUser= userObj.user;
        //await updateProfile(user,{displayName: form.username});
        const idToken = await getIdToken(loggedinUser);

         apiGetUserDetails(idToken)
           .then((response) => {
             
             props.handleLogin(loggedinUser, response.data);
           })
           .catch((error) => {
             props.setLoading(false);
             console.log(error);
           });
      
      })
       .catch((error) =>{
         
         setErrorMsg(error.code);
         console.log(errorMsg);
         props.setLoading(false);
         setOpen(true);

       });
  
     
    };

    //Google Authentication

    const handleGoogleLogin = () => {
      props.setLoading(true);
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      const auth = getAuth();
      signInWithRedirect(auth, provider);
    };
    
    return (
      <div c="Login_root" sx={customStyles.root}>
        {
          <img
            src="http://localhost:3000/assets/background.png"
            alt="background"
            style={{
              position: "absolute",
              objectFit: "cover",
              width: "100%",
              height: "100vh",
              opacity: "0.5",
            }}
          />
        }
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={3}>
            <Card
              style={{
                background: "#fff",
                zIndex: 1000,
                opacity: 0.9,
                borderBottom: "2px solid rgb(212, 212, 212)",
              }}
            >
              <Typography className="Login_title" sx={customStyles.title}>
                Log In & Start Sharing
              </Typography>
              <Typography className="Login_text" sx={customStyles.text}>
                Don’t have an account?
                <span style={{ padding: "3px 3px" }}>
                  <Link href="/signup" style={{ color: "#78B326" }}>
                    Sign Up
                  </Link>
                </span>
              </Typography>

              <TextField
                variant="outlined"
                className="Login_input"
                sx={customStyles.input}
                fullWidth
                type="text"
                placeholder="Username or email"
                size="small"
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
              <TextField
                variant="outlined"
                className="Login_input"
                sx={customStyles.input}
                fullWidth
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                size="small"
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        style={{ padding: 8 }}
                        edge="end"
                      >
                        {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                className="Login_linkContainer"
                sx={customStyles.linkContainer}
              >
                <Link className="Login_link" href="#" sx={customStyles.link}>
                  Forgot Password?
                </Link>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "0px 20px",
                }}
              >
                <button
                  className="Login_button"
                  variant="contained"
                  style={{
                    background: "#78B326",
                    width: "100%",
                    opacity: 0.9,
                  }}
                  sx={customStyles.button}
                  onClick={handleSubmit}
                >
                  <Typography
                    className="Login_buttonText"
                    sx={customStyles.buttonText}
                  >
                    Log In
                  </Typography>
                </button>
                <Snackbar
                  anchorOrigin={{vertical,horizontal}}
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMsg}
                  </MuiAlert>
                </Snackbar>
              </div>
              <Grid container>
                <Grid
                  className="Login_DividerContainer"
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={customStyles.DividerContainer}
                >
                  <div className="Login_Divider" sx={customStyles.Divider}>
                    <hr className="Login_LeftHr" sx={customStyles.LeftHr} />
                    OR
                    <hr className="Login_RightHr" sx={customStyles.RightHr} />
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "0px 20px 40px",
                }}
              >
                <button
                  onClick={handleGoogleLogin}
                  className="Login_button"
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #898989",
                    boxSizing: "border-box",
                    width: "100%",
                    opacity: 0.8,
                  }}
                  sx={customStyles.button}
                >
                  <FcGoogle size={20} />
                  <Typography
                    className="Login_buttonText"
                    style={{ color: "#333333", padding: "0px 15px" }}
                    sx={customStyles.buttonText}
                  >
                    Log In with Google
                  </Typography>
                </button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
}

export default (LogIn);