import axios from 'axios';
import { t } from 'typy';

export const apiUrl = (data, idToken) => new Promise ((resolve,reject) => {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/url`,
      data: data,
    };
   
    if (!t(idToken).isNullOrUndefined) {
        
        config.headers = { 
            'Authorization': `Bearer ${idToken}`, 
            'Content-Type': 'application/json'
        };
    }
    
    axios(config)
        .then(function (response) {
            resolve(response)
        })
        .catch(function (error) {
            reject(error)
        });
})