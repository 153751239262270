import { ResponsiveBar } from '@nivo/bar';
import { barData } from "./BarChartData";

const MyResponsiveBar = () => (
  <ResponsiveBar
    data={barData}
    keys={["browsing"]}
    indexBy="country"
    margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
    padding={0.8}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }}
    enableLabel={false}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#78B326",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#78B326",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "fries",
        },
        id: "dots",
      },
      {
        match: {
          id: "sandwich",
        },
        id: "lines",
      },
    ]}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      // legend: 'country',
      legendPosition: "middle",
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      // legend: 'food',
      legendPosition: "middle",
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    // legends={[
    //     {
    //         dataFrom: 'keys',
    //         anchor: 'bottom-right',
    //         direction: 'column',
    //         justify: false,
    //         translateX: 120,
    //         translateY: 0,
    //         itemsSpacing: 2,
    //         itemWidth: 100,
    //         itemHeight: 20,
    //         itemDirection: 'left-to-right',
    //         itemOpacity: 0.85,
    //         symbolSize: 20,
    //         effects: [
    //             {
    //                 on: 'hover',
    //                 style: {
    //                     itemOpacity: 1
    //                 }
    //             }
    //         ]
    //     }
    // ]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);

export default MyResponsiveBar;