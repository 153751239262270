import  React, { useState , useEffect} from 'react';
import { useUserAuth} from '../../Container/Authentication/Auth';
import { Card, TextField, Typography, IconButton, Alert  } from '@mui/material';
import Grid from '@mui/material/Grid';
import {  withStyles } from '@mui/styles';
import { FcGoogle } from 'react-icons/fc';
import { green, grey } from '@mui/material/colors';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { passwordValidator } from '../../helpers/PasswordValidator';
import { usernameValidator } from '../../helpers/UsernameValidator';
import validator from 'validator';
import { organizationSizeValidator } from '../../helpers/OrganizationSizeValidator';
import { apiUser } from '../../Utility/Apis/apiUser';
import { getIdToken, updateProfile } from 'firebase/auth';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { GoogleAuthProvider, signInWithRedirect, getAuth } from "firebase/auth";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";


const customStyles = {
  root: {
    width: "100%",
    height: "86vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.08)",
    padding: { sm: "18px 8px", md: "10px 0px" },
    position: "relative",
    overflowX: "disabled",

    // [theme.breakpoints.down('sm')]: {
    //     padding: '18px 8px'
    // }
  },
  title: {
    

    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 600,
    // fontSize: "30px",
    lineHeight: "116%",
    textAlign: "center",
    color: "#333333",
    padding: "30px 40px 12px",
    fontSize: { sm: "24px", md: "30px" },
    opacity: 0.9,
  },
  text: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: {sm: "12px", md:"16px"},
    lineHeight: "25px",
    textAlign: "center",
    color: "#898989",
    paddingBottom: "10px",
    
    opacity: 0.8,
  },
  
  DividerContainer: {
    padding: "8px 0px",
    textAlign: "center",
    opacity: 0.8,
  },
  Divider: {
    width: "90%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    opacity: 0.8,
  },
  LeftHr: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%",
    float: "left",
  },
  RightHr: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%",
    float: "right",
  },
  input: {
    display: "flex",
    alignItems: "flex-start",
    padding: "12px 20px 0px",
    background: "#FFFFFF",
  },
  button: {
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 60px",
    border: "0px solid #FFF",
    height: "40px",
  },
  footer: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: {sm:"12px", md:"14px"},
    lineHeight: "19px",
    textAlign: "center",
    color: "#898989",
    padding: "15px 0px",
    
  },
  buttonText: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#FFFFFF",
    lineHeight: "33px",
    fontSize: { sm: "14px", md: "20px" },
    opacity: 0.8,
  },
};

const customStyles1 = {
    checkboxTitle: {
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '18px',
        color:'#898989',        
        flex: 'none',
        order: 1,
        flexGrow: 0,
        margin: '0px 28px',
        '&$focused': {
            color: '#898989',
        },
    },
    checkboxOptions: {
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#898989',
        margin: '0px 12px',
    }
}

const GreenCheckbox = withStyles({
    root: {
            color: grey[400],
            padding: 4,
            '&$checked': {
                color: green[600],
            },
            },
            checked: {},
    })((props) => <Checkbox color="default" {...props} />);

const SignUp = (props) => {

   
   const [card, setCard] = useState("userdetails");
   const [showPassword, setShowPassword] = useState(false);
   const [emailError, setEmailError] = useState(false);
   const [passwordError, setPasswordError] = useState(false);
   const [usernameError, setUsernameError] = useState(false);
  
   const [additionalInfo, setAdditionalInfo] = useState({
     name: "",
     title: "",
     department: "",
     organization: "",
   });
   const [additionalInfoError, setAdditionalInfoError] = useState({
     nameError: "",
     titleError: "",
     departmentError: "",
     organizationError: "",
   });

   const [interests, setInsterests] = useState([]);
const [errorMsg,setErrorMsg]= useState("");
   


   const handleInterestsChanged = (e, label) => {
     let newInterests = [...interests];
     const newChecked = e.target.checked;
     if (newChecked) {
       newInterests.push(label);
     } else {
       newInterests = newInterests.filter((item) => item !== label);
     }
     setInsterests([...newInterests]);
   };

   //Email-password Authentication

   const [form, setForm] = useState({
     username: "",
     email: "",
     password: "",
   });
   const {register}= useUserAuth();
   const [user,setUser]=useState([]);
   const [open, setOpen] = useState(false);
   const [dialogOpen, setDialogOpen] = useState(false);
   const vertical = "bottom";
   const horizontal = "center";



// useEffect(() => {
//   console.log(errorMsg);

//   setOpen(true);
// }, [errorMsg]);


   const handleClose = () => {
     setOpen(false);
   };
   const handleDialogClose = () => {
     setDialogOpen(false);
   };

   const handleSubmit = async (e) => {

    e.preventDefault();

     let error = false;
     if (!usernameValidator(additionalInfo.name)) {
       error = true;
       setAdditionalInfoError({ ...additionalInfoError, nameError: true });
     }
     if (!usernameValidator(additionalInfo.title)) {
       error = true;
       setAdditionalInfoError({ ...additionalInfoError, titleError: true });
     }
     if (!usernameValidator(additionalInfo.department)) {
       error = true;
       setAdditionalInfoError({
         ...additionalInfoError,
         departmentError: true,
       });
     }
     if (!organizationSizeValidator(additionalInfo.organization)) {
       error = true;
       setAdditionalInfoError({
         ...additionalInfoError,
         organizationError: true,
       });
     }
     if (!error) {
      
       
        // await register(form.email,form.password)
        // .then(async (userObj) => {
        // const user= userObj.user;
        // await updateProfile(user,{displayName: form.username});
          
           const idToken =await getIdToken(user);
           var data = JSON.stringify({
           name: additionalInfo.name,
           title: additionalInfo.title,
           department: additionalInfo.department,
           organization_size: additionalInfo.organization,
           uses: interests,
         });
         apiUser(data, idToken)
           .then((response) => {
             console.log("user data saved");
             console.log("response", data);
             props.handleLogin(user, data);
           })

           .catch((error) => {
             
             console.log(error);
             setErrorMsg(error.code);
           });
 
      //  .catch((err) => {
      //   props.setLoading(false);
      //   console.log(err);
      //      setErrorMsg(err.code);
          
         
      //   })
   }};
    

   
   //Google Authentication


 const handleGoogleLogin = () => {
  
   props.setLoading(true);
   const provider = new GoogleAuthProvider();
   provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
   const auth = getAuth();
   signInWithRedirect(auth, provider);
 };

  

   const proceedToAdditionalInfo = async(e) => {
     e.preventDefault();
     let error = false;
     if (!validator.isEmail(form.email)) {
       error = true;
       setEmailError(true);
     }
     if (!passwordValidator(form.password)) {
       error = true;
       setPasswordError(true);
     }
     if (!usernameValidator(form.username)) {
       error = true;
       setUsernameError(true);
     }
     if (!error) {
      
       await register(form.email, form.password).then(async (userObj) => {
         setUser(userObj.user);
         await updateProfile(userObj.user, { displayName: form.username });
        setCard("additionalinfo");
       })
        .catch((err) => {
          if(err.code==="auth/email-already-in-use"){
            setDialogOpen(true);
          console.log(err);
          }else{
          setErrorMsg(err.code) ; 
          console.log(errorMsg);
          setOpen(true);
          }
          })    
     }
    };

    
    return (
      <div className="Signup_root" sx={customStyles.root}>
        <img
          src="http://localhost:3000/assets/background.png"
          alt="background"
          style={{
            position: "absolute",
            objectFit: "cover",
            width: "100%",
            height: "100vh",
            opacity: "0.5",
          }}
        />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="customized-dialog-title"
            sx={{ alignItems: "center" }}
          >
            <DialogContent sx={{ alignItems: "center" }}>
              <DialogContentText>
                Email is already registered.Login to proceed
              </DialogContentText>
            </DialogContent>
            <DialogActions
              autoFocus
              onClick={() => {
                window.location.pathname = "/login";
              }}
              sx={{
                justifyContent: "center",
                padding: "5px 5px",
                background: "#78B326",
              }}
            >
              Login
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open && errorMsg !== ""}
            autoHideDuration={12000}
            onClose={handleClose}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMsg}
            </MuiAlert>
          </Snackbar>
          <Grid item xs={3}>
            {card === "userdetails" ? (
              <Card
                style={{
                  background: "#fff",
                  zIndex: 1100,
                  opacity: 0.9,
                  borderBottom: "2px solid rgb(212, 212, 212)",
                }}
              >
                <Typography className="Signup_title" sx={customStyles.title}>
                  Sign Up & Start Shorting
                </Typography>
                <Typography className="Signup_text" sx={customStyles.text}>
                  Already have an account?
                  <span style={{ padding: "0px 3px" }}>
                    <Link href="/login" style={{ color: "#78B326" }}>
                      Log In
                    </Link>
                  </span>
                </Typography>

                <TextField
                  variant="outlined"
                  className="Signup_input"
                  sx={customStyles.input}
                  fullWidth
                  type="text"
                  placeholder="Username"
                  size="small"
                  onChange={(e) => {
                    setForm({ ...form, username: e.target.value });
                    if (usernameValidator(e.target.value)) {
                      setUsernameError(false);
                    }
                  }}
                  error={usernameError}
                  value={form.username}
                  helperText={
                    usernameError
                      ? "Username should be more than 6 characters"
                      : ""
                  }
                />
                <TextField
                  variant="outlined"
                  className="Signup_input"
                  sx={customStyles.input}
                  fullWidth
                  type="text"
                  placeholder="E-mail"
                  size="small"
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                    if (validator.isEmail(e.target.value) && emailError) {
                      setEmailError(false);
                    }
                  }}
                  error={emailError}
                  value={form.email}
                  helperText={emailError ? "A valid email ID is required" : ""}
                />
                <TextField
                  variant="outlined"
                  className="Signup_input"
                  sx={customStyles.input}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  size="small"
                  onChange={(e) => {
                    setForm({ ...form, password: e.target.value });
                    if (passwordValidator(e.target.value)) {
                      setPasswordError(false);
                    }
                  }}
                  error={passwordError}
                  value={form.password}
                  helperText={
                    passwordError
                      ? "Password should be more than 6 characters"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          style={{ padding: 8 }}
                          edge="end"
                        >
                          {showPassword ? (
                            <AiFillEye />
                          ) : (
                            <AiFillEyeInvisible />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "16px 20px 0px",
                  }}
                >
                  <button
                    className="Signup_button"
                    onClick={proceedToAdditionalInfo}
                    type="submit"
                    style={{
                      background: "#78B326",
                      width: "100%",
                      opacity: 0.9,
                    }}
                    sx={customStyles.button}
                  >
                    <Typography
                      className="Signup_buttonText"
                      sx={customStyles.buttonText}
                    >
                      Sign up
                    </Typography>
                  </button>
                </div>
                <Grid container>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="Signup_DividerContainer"
                    sx={customStyles.DividerContainer}
                  >
                    <div className="Signup_Divider" sx={customStyles.Divider}>
                      <hr className="Signup_LeftHr" sx={customStyles.LeftHr} />
                      OR
                      <hr
                        className="Signup_RightHr"
                        sx={customStyles.RightHr}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "0px 20px",
                  }}
                >
                  <button
                    className="Signup_button"
                    sx={customStyles.button}
                    onClick={handleGoogleLogin}
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #898989",
                      boxSizing: "border-box",
                      width: "100%",
                    }}
                  >
                    <FcGoogle size={20} />
                    <Typography
                      className="Signup_buttonText"
                      sx={customStyles.buttonText}
                      style={{ color: "#333333", padding: "0px 15px" }}
                    >
                      Sign up with Google
                    </Typography>
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "20px 0px",
                  }}
                >
                  <Typography
                    className="Signup_footer"
                    sx={customStyles.footer}
                  >
                    By creating an account, you are agreeing to nlnk’s <br />
                    <Link href="#" style={{ color: "#78B326" }}>
                      Terms of service
                    </Link>
                    <span style={{ padding: "0px 4px" }}> & </span>
                    <Link href="#" style={{ color: "#78B326" }}>
                      Privacy Policy
                    </Link>
                  </Typography>
                </div>
              </Card>
            ) : null}
            {card === "additionalinfo" ? (
              <div className="addInfo_root" sx={customStyles.root}>
                <Card style={{ background: "#fff", zIndex: 1000 }}>
                  <Typography className="addInfo_title" sx={customStyles.title}>
                    Additional Information
                  </Typography>

                  <TextField
                    variant="outlined"
                    className="addInfo_input"
                    sx={customStyles.input}
                    fullWidth
                    type="text"
                    placeholder="Name"
                    size="small"
                    onChange={(e) => {
                      setAdditionalInfo({
                        ...additionalInfo,
                        name: e.target.value,
                      });
                      if (usernameValidator(e.target.value)) {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          nameError: false,
                        });
                      } else {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          nameError: true,
                        });
                      }
                    }}
                    error={additionalInfoError.nameError > 0}
                    value={additionalInfo.name}
                    helperText={
                      additionalInfoError.nameError
                        ? "Username should be more than 6 characters."
                        : ""
                    }
                  />
                  <TextField
                    variant="outlined"
                    className="addInfo_input"
                    sx={customStyles.input}
                    fullWidth
                    type="text"
                    placeholder="Title"
                    size="small"
                    onChange={(e) => {
                      setAdditionalInfo({
                        ...additionalInfo,
                        title: e.target.value,
                      });
                      if (usernameValidator(e.target.value)) {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          titleError: false,
                        });
                      } else {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          titleError: true,
                        });
                      }
                    }}
                    error={additionalInfoError.titleError > 0}
                    value={additionalInfo.title}
                    helperText={
                      additionalInfoError.titleError
                        ? "Title should be more than 6 characters."
                        : ""
                    }
                  />
                  <TextField
                    variant="outlined"
                    className="addInfo_input"
                    sx={customStyles.input}
                    fullWidth
                    type="text"
                    placeholder="Department"
                    size="small"
                    onChange={(e) => {
                      setAdditionalInfo({
                        ...additionalInfo,
                        department: e.target.value,
                      });
                      if (usernameValidator(e.target.value)) {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          departmentError: false,
                        });
                      } else {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          departmentError: true,
                        });
                      }
                    }}
                    error={additionalInfoError.departmentError > 0}
                    value={additionalInfo.department}
                    helperText={
                      additionalInfoError.departmentError
                        ? "Title should be more than 6 characters."
                        : ""
                    }
                  />
                  <TextField
                    variant="outlined"
                    className="addInfo_input"
                    sx={customStyles.input}
                    fullWidth
                    type="text"
                    placeholder="No. of people in the organization"
                    size="small"
                    onChange={(e) => {
                      setAdditionalInfo({
                        ...additionalInfo,
                        organization: e.target.value,
                      });
                      if (organizationSizeValidator(e.target.value)) {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          organizationError: false,
                        });
                      } else {
                        setAdditionalInfoError({
                          ...additionalInfoError,
                          organizationError: true,
                        });
                      }
                    }}
                    error={additionalInfoError.organizationError > 0}
                    value={additionalInfo.organization}
                    helperText={
                      additionalInfoError.organizationError
                        ? "Organization size should be in the range 100 to 200"
                        : ""
                    }
                  />
                  <div style={{ background: "#fff", padding: "16px 0px 0px" }}>
                    <FormControl
                      component="fieldset"
                      style={{ background: "#fff" }}
                      className="addInfo_formControl"
                      sx={customStyles.formControl}
                    >
                      <FormLabel
                        component="legend"
                        className="addInfo_checkboxTitle"
                        sx={customStyles1.checkboxTitle}
                      >
                        How are you planning to use nlnk.in
                      </FormLabel>
                      <div style={{ height: "6px" }} />
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControlLabel
                              className="addInfo_checkboxOptions"
                              sx={customStyles1.checkboxOptions}
                              control={
                                <GreenCheckbox
                                  checked={interests.includes(
                                    "Digital Campaigns"
                                  )}
                                  onChange={(e) =>
                                    handleInterestsChanged(
                                      e,
                                      "Digital Campaigns"
                                    )
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>
                                  Digital Campaigns
                                </span>
                              }
                            />
                            <FormControlLabel
                              className="addInfo_checkboxOptions"
                              sx={customStyles1.checkboxOptions}
                              control={
                                <GreenCheckbox
                                  checked={interests.includes("Branding")}
                                  onChange={(e) =>
                                    handleInterestsChanged(e, "Branding")
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>Branding</span>
                              }
                            />
                            <FormControlLabel
                              className="addInfo_checkboxOptions"
                              sx={customStyles1.checkboxOptions}
                              control={
                                <GreenCheckbox
                                  checked={interests.includes("Influencer")}
                                  onChange={(e) =>
                                    handleInterestsChanged(e, "Influencer")
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>Influencer</span>
                              }
                            />
                            <FormControlLabel
                              className="addInfo_checkboxOptions"
                              sx={customStyles1.checkboxOptions}
                              control={
                                <GreenCheckbox
                                  checked={interests.includes("Other")}
                                  onChange={(e) =>
                                    handleInterestsChanged(e, "Other")
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>Other</span>
                              }
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControlLabel
                              className="addInfo_checkboxOptions"
                              sx={customStyles1.checkboxOptions}
                              control={
                                <GreenCheckbox
                                  checked={interests.includes(
                                    "SMS Notifications"
                                  )}
                                  onChange={(e) =>
                                    handleInterestsChanged(
                                      e,
                                      "SMS Notifications"
                                    )
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>
                                  SMS Notifications
                                </span>
                              }
                            />
                            <FormControlLabel
                              className="addInfo_checkboxOptions"
                              sx={customStyles1.checkboxOptions}
                              control={
                                <GreenCheckbox
                                  checked={interests.includes(
                                    "Offline Campaigns"
                                  )}
                                  onChange={(e) =>
                                    handleInterestsChanged(
                                      e,
                                      "Offline Campaigns"
                                    )
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>
                                  Offline Campaigns
                                </span>
                              }
                            />
                            <FormControlLabel
                              className="addInfo_checkboxOptions"
                              sx={customStyles1.checkboxOptions}
                              control={
                                <GreenCheckbox
                                  checked={interests.includes(
                                    "Internal Communication"
                                  )}
                                  onChange={(e) =>
                                    handleInterestsChanged(
                                      e,
                                      "Internal Communication"
                                    )
                                  }
                                />
                              }
                              label={
                                <span style={{ fontSize: 14 }}>
                                  Internal Communication
                                </span>
                              }
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "0px 20px",
                      margin: "20px 0px 40px",
                    }}
                  >
                    <button
                      className="addInfo_button"
                      sx={customStyles.button}
                      onClick={handleSubmit}
                      style={{
                        background: "#78B326",
                        width: "100%",
                        opacity: 0.9,
                      }}
                    >
                      <Typography
                        className="addInfo_buttonText"
                        sx={customStyles.buttonText}
                      >
                        Save
                      </Typography>
                    </button>
                  </div>
                </Card>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </div>
    );
}

export default (SignUp);