import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FcMenu } from "react-icons/fc";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
//import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import theme from "./../../theme.js";

//import theme from "./../../theme.js";

const customStyle = {
  appBar: {
    background: "#FFFFFF",
  },

  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: { sm: "0px 8px", md: "0px 80px" },
    alignItems: { sm: "center" },
  },
  textColor: {
    background: "#bdbdbd",
  },

  button: {
    color: "#000000",
    textTransform: "none",
    "&:hover": {
      background: "transparent",
    },
  },
  containedButton: {
    marginleft: "auto",
    textTransform: "none",
    background: "#78B326",
    borderRadius: 40,
    "&:hover": {
      background: "#78B326",
    },
  },
};

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 4,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const ElevateAppBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

//const theme= useTheme();

 const isMobile = useMediaQuery(
   theme.breakpoints.down("md") ||
     theme.breakpoints.down("sm") ||
     theme.breakpoints.down("xs")
 );
    
//  const isMobile = () => {
//    if (props.width === 'sm' || props.width === 'xs') {
//      return true;
//    }
//    return false;
//  };


  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className="Nav_appBar" sx={customStyle.appBar}>
          <Toolbar>
            <Grid container className="Nav_root" sx={customStyle.root}>
              <div>
                <Logo
                  height={isMobile ? 18 : 38}
                  onClick={() => (window.location.pathname = "/")}
                />
              </div>

              {window.location.pathname !== "/signup" &&
              window.location.pathname !== "/login" ? (
                isMobile ? (
                  <div>
                    <Button
                      marginleft="auto"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <FcMenu />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>Why?</MenuItem>
                      <MenuItem onClick={handleClose}>Feature</MenuItem>
                      <MenuItem onClick={handleClose}>Pricing</MenuItem>
                      <MenuItem
                        onClick={() => (window.location.pathname = "/login")}
                        className="Nav_button"
                        sx={customStyle.button}
                      >
                        Login
                      </MenuItem>
                      <MenuItem
                        variant="contained"
                        onClick={() => (window.location.pathname = "/signup")}
                      >
                        Signup
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <Button className="Nav_button" sx={customStyle.button}>
                      Why?
                    </Button>
                    <div style={{ width: 8 }} />
                    <Button className="Nav_button" sx={customStyle.button}>
                      Feature
                    </Button>
                    <div style={{ width: 8 }} />
                    <Button className="Nav_button" sx={customStyle.button}>
                      Pricing
                    </Button>
                    <div style={{ width: 8 }} />
                    <Button
                      onClick={() => (window.location.pathname = "/login")}
                      className="Nav_button"
                      sx={customStyle.button}
                    >
                      Login
                    </Button>
                    <div style={{ width: 8 }} />
                    <Button
                      variant="contained"
                      onClick={() => (window.location.pathname = "/signup")}
                      className="Nav_containedButton"
                      sx={customStyle.containedButton}
                      style={{ color: "#FFFFFF" }}
                    >
                      Signup
                    </Button>
                  </div>
                )
              ) : null}
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Container>
        <Box my={2}>
          {/* {[...new Array(12)]
            .map(
              () => `Cras mattis consectetur purus sit amet fermentum.
                    Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                    Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
            )
            .join('\n')} */}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ElevateAppBar;
