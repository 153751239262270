import axios from "axios";

export const apiGetUserDetails = (idToken) =>
  new Promise((resolve, reject) => {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/user`,
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      }
     
    };
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
