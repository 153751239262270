import axios from 'axios';
import { encode } from 'base64-arraybuffer'

export const apiQrCode = async (url,idToken) => {
    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/api/qrcode?url=${url}`,
        headers: { 
            'Authorization': `Bearer ${idToken}`, 
            'Content-Type': 'application/json'
        },
        responseType : 'arraybuffer'
    };
    const response = await axios(config);
   
    return encode(response.data);
}