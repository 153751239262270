import {auth} from '../../firebase';
import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  getIdToken
} from "firebase/auth";
const userAuthContext = createContext();
  
// export const register = async({email, password})=>{
//     const resp = await firebase.auth()
//       .createUserWithEmailAndPassword(email, password);
     
//     return resp.user;
// }
  
// export const login = async({email, password})=>{
  
//     const res = await firebase.auth()
//       .signInWithEmailAndPassword(email, password);
//     return res.user;
//   }


export function UserAuthContextProvider({children}){
  const [currentUser,setCurrentUser]= useState("");
  const [token, setToken] = useState("");
  function register(email,password){
    return createUserWithEmailAndPassword(auth,email, password);
  }

  function login(email, password){
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

   useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if(currentUser){
      console.log("Auth", currentUser);
      setToken(getIdToken(currentUser));
      setCurrentUser(currentUser);
      }
      
     });

     return () => {
       unsubscribe();
     };
   }, []);

  return (
    <userAuthContext.Provider
      value={{ token, currentUser, login, register, logOut, googleSignIn }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

