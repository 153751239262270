import axios from 'axios';

export const apiUser = (data, idToken) => new Promise((resolve, reject) => {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/api/user`,
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
        .then(function (response) {
        resolve(response);
        })
        .catch(function (error) {
            reject(error);
        });
})