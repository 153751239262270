import React, {  useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  Card,
  Typography,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  CircularProgress,
  Box
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useMediaQuery } from "@mui/material";
import theme from "./../../theme.js";
import Skeleton from 'react-loading-skeleton';
import SearchInput from '../SearchInput/SearchInput';
import MyResponsiveBar from '../Charts/BarChart';
import MyResponsiveLine from '../Charts/LineChart';
import MyResponsivePie from '../Charts/PieChart';
import Button from '@mui/material/Button';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import Avatar from "@mui/material/Avatar";
import { withRouter } from 'react-router';
import { ReactComponent as AdminVector } from '../../assets/AdminVector.svg';
import { ReactComponent as AdminLogo } from '../../assets/AdminLogo.svg';
import ChatWidget from '@papercups-io/chat-widget';
import { apiList } from '../../Utility/Apis/apiList';
import { t } from 'typy';
//import { MdDeleteForever } from 'react-icons/md';


//Mobile View
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { apiQrCode } from '../../Utility/Apis/apiQrCode';
import { analyticsVisits, analyticsCountries, analyticsBrowsers } from '../../Utility/Apis/analytics';
import { useUserAuth } from '../../Container/Authentication/Auth';

const TEST_ACCOUNT_ID = "12a91956-a1ba-4b1a-9c30-58501036a190";

const customStyles = {
  rootLeft: {
    width: "100%",
    minHeight: "100vh",
    background: "#FFFFFF",
    boxShadow: "4px 0px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "0px 30px 30px 0px",
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    padding: "10px 10px 10px 20px",
    margin: "10px ",
  },
  nav: {
    width: "100%",
    height: "14vh",
    left: "0px",
    top: "0px",
    background: "#78B326",
    borderRadius: "0px 30px 30px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  commonCss: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: 500,
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  hr: {
    marginLeft: "auto",
    marginRight: "10px",
    width: "90%",
    float: "left",
  },
  textField: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "28px",
    fontSize: "15px",
    lineHeight: "22px",
    color: "#898989",
  },
  buttonShorten: {
    textTransform: "none",
    background: "#78B326",
    borderRadius: "28px",
    width: 108,
    height: 40,
    paddingRight: "0px",
  },
  containedButton: {
    textTransform: "none",
    color: "#FFF",
    background: "#474EBB",
    borderRadius: 20,
    "&:hover": {
      background: "#474EBB",
    },
    fontSize: { xs: "8px", sm: "8px", md: "10px", lg: "12px" },
    height: 25,
    width: { xs: "80px", sm: "80px", md: "16%", lg: "14%" },
  },
  lineChart: {
    width: "90%",
    height: "45vh",
    paddingBottom: "60px",
    color: "#78B326",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pieChart: {
    width: "38%",
    height: "35vh",
    paddingBottom: "40px",
    color: "#78B326",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  barChart: {
    width: "50%",
    height: "35vh",
    paddingBottom: "40px",
    color: "#78B326",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  SelectButton: {
    background: "#FFFFFF",
    textTransform: "none",
    borderRadius: 15,
    "&:hover": {
      background: "#FFFFFF",
    },
    // [theme.breakpoints.down('sm')]: {
    //     margin: '15px 0px 15px 170px',
    // }
  },
  linkSelectedButton: {
    borderRadius: 4,
    textTransform: "none",
    color: "#fff",
    background: "#78B326",
    "&:hover": {
      background: "#78B326",
    },
  },
  linkButton: {
    textTransform: "none",
    borderRadius: 4,
  },
};

//Mobile View
const customStylesMobile = {
  root: {
    flexGrow: 1,
    background: "#78B326",
  },
  appBar: {
    backgroundColor: "#78B326",
  },
  logo: {
    height: 32,
    color: "#ffffff",
    paddingTop: 8,
  },
  rootMobile: {
    backgroundColor: "#fff",
    padding: "15px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerTitle: {
    textAlign: "center",
    backgroundColor: "#78B326",
    padding: "6px 0px",
  },
  linkSelectedButton: {
    borderRadius: 4,
    textTransform: "none",
    color: "#fff",
    background: "#78B326",
    "&:hover": {
      background: "#78B326",
    },
  },
  linkButton: {
    textTransform: "none",
  },
};

const Admin = (props) => {

    //const classes = useStyles();
    //const classesMobile = useStylesMobile();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const [urls, setUrls] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [analyticsLoading, setAnalyticsLoading] = useState(true);
    const [urlLoading, setUrlLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const { disco } = props;

    const colors = [
      "#1890ff",
      "#f5222d",
      "#7cb305",
      "#52c41a",
      "#13c2c2",
      "#722ed1",
      "#eb2f96",
    ];
    const [primaryColor, setPrimaryColor] = useState(colors[0]);
const { logOut } = useUserAuth();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      
    };

    const handleDialogClose = () => {
      setOpen(false);
    };

      const handleCopy = () => {
          navigator.clipboard.writeText(selectedUrl.short_url);
        }
    

    useEffect(() => {
      if (!disco) {
        return;
      }
      const interval = setInterval(() => {
        const idx = colors.indexOf(primaryColor);
        const next = (idx + 1) % (colors.length - 1);
        setPrimaryColor(colors[next]);
      }, 2000);
      return () => clearInterval(interval);
    }, [disco, colors, primaryColor]);

    useEffect(() => {
      if (props.user === null && props.loading === false) {
        props.history.replace("/");
      }
    }, [props.user, props.loading]);

    // const isMobile = () => {
    //   if (props.width === "xs" || props.width === "sm") {
    //     return true;
    //   }
    //   return false;
    // };

const isMobile = useMediaQuery(
   theme.breakpoints.down("md") ||
     theme.breakpoints.down("sm") ||
     theme.breakpoints.down("xs")
 );


    // const isMobile1 = () => {
    //     return props.width === 'xs' || props.width === 'sm';
    // }

    const getUserLinks = async () => {
      try {
        setUrlLoading(true);
        const { idToken } = await props.getUserData();
        
        apiList(idToken)
          .then(async function (response) {
            console.log(response);
            for (let i = 0; i < response.data.urls.length; i++) {
              const qr = await getQrCode(
                response.data.urls[i].short_url,
                idToken
              );
              response.data.urls[i].qrData = qr;
            }
            setUrls(response.data.urls);
            if (response.data.urls.length === 0) {
              setAnalyticsLoading(false);
            } else {
              setSelectedUrl(response.data.urls[0]);
            }
           
            setUrlLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setUrlLoading(false);
          });
      } catch {
        setUrlLoading(false);
      }
    };

    function stringAvatar(name) {
      return {
        sx: {
          bgcolor: "#474EBB",
        },
        children: `${name.split(" ")[0][0]}`,
      };
    }

    const getQrCode = async (url, idToken) => {
      try {
        // var config = {
        //     method: 'get',
        //     url: `https://223fad39af65.ngrok.io/api/qrcode?url=${url}`,
        //     headers: {
        //         'Authorization': `Bearer ${idToken}`,
        //         'Content-Type': 'application/json'
        //     },
        //     responseType : 'arraybuffer'
        // };
        // const response = await axios(config);
        // console.log('response.data:', encode(response.data));
        // return encode(response.data);

        const data = await apiQrCode(url, idToken);
        return data;
      } catch {
        return "";
      }
    };

    const fetchAnalytics = async (urlData) => {
      try {
        setAnalyticsLoading(true);

        console.log("Fetching Analytics");

        const { idToken } = await props.getUserData();
        const visits = await analyticsVisits(idToken, urlData.short_url);
        const countries = await analyticsCountries(idToken, urlData.short_url);
        const browsers = await analyticsBrowsers(idToken, urlData.short_url);

        setAnalytics({ visits, countries, browsers });
        console.log({ visits, countries, browsers });
        setAnalyticsLoading(false);
      } catch (e) {
        console.log("error : ", e.message);
        setAnalyticsLoading(false);
      }
    };

    useEffect( () =>  {
       // console.log(props.user.email);
      if (props.user !== null && props.loading === false) {

        getUserLinks();
      }
    }, [props.user, props.loading ]);

    useEffect(() => {
      if (!t(selectedUrl).isNullOrUndefined) {
        fetchAnalytics(selectedUrl);
      }
    }, [selectedUrl]);

    return (
      <>
        {isMobile ? (
          <>
            <div className="Admin_root" style={customStylesMobile.root}>
              <AppBar
                className="Admin_appBar"
                sx={customStylesMobile.appBar}
                position="static"
              >
                <Toolbar>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    sx={{ color: "red" }}
                    onClick={() => setDrawerOpen(true)}
                  >
                    <AdminVector style={{ color: "#FFFFFF" }} height={16} />
                  </Button>
                  <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                  >
                    <div style={{ minWidth: "300px" }}>
                      <Typography
                        className="Admin_drawerTitle"
                        sx={customStylesMobile.drawerTitle}
                      >
                        Your Links
                      </Typography>
                      {!urlLoading ? (
                        urls.map((urlData) => (
                          <Card className="Admin_card" sx={customStyles.card}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ paddingRight: "4px" }}>
                                <Typography
                                  className="Admin_commonCss"
                                  style={customStyles.commonCss}
                                  sx={{
                                    fontSize: "14px",
                                    lineHeight: "normal",
                                  }}
                                >
                                  Download App
                                </Typography>
                                <hr
                                  className="Admin_hr"
                                  style={customStyles.hr}
                                />
                                <div
                                  style={{
                                    height: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={`data:image/svg+xml;base64,${urlData.qrData}`}
                                    alt=""
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      marginLeft: "50px",
                                    }}
                                  />
                                </div>
                                <Typography
                                  className="Admin_commonCss"
                                  sx={customStyles.commonCss}
                                  style={{
                                    fontSize: "10px",
                                    lineHeight: "20px",
                                    color: "#898989",
                                  }}
                                >
                                  <Button
                                    onClick={() => setSelectedUrl(urlData)}
                                    variant={
                                      selectedUrl &&
                                      selectedUrl.short_url ===
                                        urlData.short_url
                                        ? "contained"
                                        : "outlined"
                                    }
                                    disableElevation
                                    className="Admin_LinkButton"
                                    sx={
                                      selectedUrl &&
                                      selectedUrl.short_url ===
                                        urlData.short_url
                                        ? customStyles.linkSelectedButton
                                        : customStyles.linkButton
                                    }
                                  >
                                    nlnk.in/{urlData.short_url}
                                  </Button>
                                </Typography>
                                <Typography
                                  className="Admin_commonCss"
                                  sx={customStyles.commonCss}
                                  style={{
                                    fontSize: "10px",
                                    lineHeight: "20px",
                                    color: "#898989",
                                  }}
                                >
                                  radialcode/downloadapp.apk
                                  {/* <IconButton style={{padding: 6, margin: 4}}>
                                                                            <MdDeleteForever size={12} color='#e76f51'/>
                                                                        </IconButton> */}
                                </Typography>
                              </div>
                            </div>
                          </Card>
                        ))
                      ) : (
                        <div style={{ padding: 12 }}>
                          <Skeleton count={6} width="100%" height={60} />
                        </div>
                      )}
                    </div>
                  </Drawer>

                  <Typography
                    variant="h6"
                    className="Admin_title"
                    sx={customStylesMobile.title}
                  >
                    <AdminLogo style={{ color: "#FFFFFF" }} height={24} />
                  </Typography>
                  <div>
                    <Button
                      variant="contained"
                      className="Admin_SelectButton"
                      sx={customStyles.SelectButton}
                      aria-controls="admin-menu-mobile"
                      aria-haspopup="true"
                      onClick={handleClick}
                      style={{ textTransform: "none" }}
                    >
                      <Avatar {...stringAvatar(props.user.name)} />

                      <AiFillCaretDown color="#333333" />
                    </Button>
                    <Menu
                      id="admin-menu-mobile"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      style={{ top: 40 }}
                    >
                      <MenuItem
                        onClick={() => {
                          window.location.pathname = "/";
                          logOut();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </Toolbar>
              </AppBar>
              <div
                className="Admin_rootMobile"
                style={customStylesMobile.rootMobile}
              >
                <SearchInput
                  getUserData={props.getUserData}
                  getUserLinks={getUserLinks}
                />
              </div>
              <div>
                <div style={{ height: 20 }} />
                <Card className="Admin_lineChart" sx={customStyles.lineChart}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "24px 28px 0px 28px",
                    }}
                  >
                    <Typography
                      className="Admin_commonCss"
                      sx={customStyles.commonCss}
                      style={{ color: "#898989", fontSize: "14px" }}
                    >
                      Total Clicks/Visits
                    </Typography>
                    <Button
                      variant="contained"
                      className="Admin_containedButton"
                      sx={customStyles.containedButton}
                    >
                      Select Date
                    </Button>
                  </div>
                  <MyResponsiveLine />
                </Card>
                <div style={{ height: 20 }} />
                <Card className="Admin_pieChart" sx={customStyles.pieChart}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "24px 28px 0px 28px",
                    }}
                  >
                    <Typography
                      className="Admin_commonCss"
                      sx={customStyles.commonCss}
                      style={{ color: "#898989", fontSize: "14px" }}
                    >
                      Click Through Countries
                    </Typography>
                    <IconButton style={{ padding: 4 }}>
                      <BsThreeDotsVertical size={12} />
                    </IconButton>
                  </div>
                  <MyResponsivePie />
                </Card>
                <div style={{ height: 20 }} />
                <Card className="Admin_barChart" sx={customStyles.barChart}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "24px 28px 0px 28px",
                    }}
                  >
                    <Typography
                      className="Admin_commonCss"
                      sx={customStyles.commonCss}
                      style={{ color: "#898989", fontSize: "14px" }}
                    >
                      Click Through Browser
                    </Typography>
                    <IconButton style={{ padding: 4 }}>
                      <BsThreeDotsVertical size={12} />
                    </IconButton>
                  </div>
                  <MyResponsiveBar />
                </Card>
                <div style={{ height: 20 }} />
              </div>
            </div>
          </>
        ) : (
          <Grid container style={{ background: "#eeeeee" }}>
            <Grid
              item
              xs={isMobile ? 4 : 2}
              className="Admin_rootLeft"
              sx={customStyles.rootLeft}
            >
              <div className="Admin_nav" style={customStyles.nav}>
                <div className="Admin_container" style={customStyles.container}>
                  <div style={{ width: 25 }} />
                  <AdminVector
                    style={{ color: "#FFFFFF" }}
                    height={30}
                    width={15}
                  />
                  <div style={{ width: 15 }} />
                  <AdminLogo
                    style={{ color: "#FFFFFF" }}
                    height={70}
                    width={80}
                  />
                </div>
              </div>
              <div
                style={{ textAlign: "center", padding: "36px 0px 16px 0px" }}
              >
                <Typography
                  className="Admin_commonCss"
                  sx={customStyles.commonCss}
                  style={{
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#898989",
                  }}
                >
                  Your Links
                </Typography>
                <hr style={{ width: "50%" }} />
              </div>
              <div style={{ height: "75vh", overflowX: "auto" }}>
                {!urlLoading ? (
                  urls.map((urlData) => (
                    <Card className="Admin_card" sx={customStyles.card}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ paddingRight: "2px" }}>
                          <Typography
                            className="Admin_commonCss"
                            sx={customStyles.commonCss}
                            style={{ fontSize: "12px", lineHeight: "normal" }}
                          >
                            Download App
                          </Typography>
                          <hr className="Admin_hr" style={customStyles.hr} />
                          <div
                            style={{
                              height: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={`data:image/svg+xml;base64,${urlData.qrData}`}
                              alt=""
                              style={{
                                width: "40px",
                                height: "40px",
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              color: "#898989",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setSelectedUrl(urlData);
                                setOpen(true);
                              }}
                              variant={
                                selectedUrl &&
                                selectedUrl.short_url === urlData.short_url
                                  ? "contained"
                                  : "outlined"
                              }
                              disableElevation
                              className="Admin_linkButton"
                              sx={
                                selectedUrl &&
                                selectedUrl.short_url === urlData.short_url
                                  ? customStyles.linkSelectedButton
                                  : customStyles.linkButton
                              }
                            >
                              nlnk.in/{urlData.short_url}
                            </Button>
                          </Typography>
                          <Dialog
                            onClose={handleDialogClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                          >
                            <DialogContent sx={{ alignItems: "center" }}>
                              <img
                                src={`data:image/svg+xml;base64,${selectedUrl.qrData}`}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                              />
                            </DialogContent>
                            <DialogActions
                              autoFocus
                              onClick={() => {
                                handleCopy();
                                handleDialogClose();
                              }}
                              sx={{
                                textTransform: "none",
                                fontSize: "20px",
                              }}
                            >
                              Copy URL
                            </DialogActions>
                          </Dialog>

                          <Typography
                            className="Admin_commonCss"
                            sx={customStyles.commonCss}
                            style={{
                              fontSize: "10px",
                              lineHeight: "20px",
                              color: "#898989",
                            }}
                          >
                            radialcode/downloadapp.apk
                            {/* <IconButton style={{padding: 6, margin: 4}}>
                                                                            <MdDeleteForever size={12} color='#e76f51'/>
                                                                        </IconButton> */}
                          </Typography>
                        </div>
                      </div>
                    </Card>
                  ))
                ) : (
                  <div style={{ padding: 12 }}>
                    <Skeleton count={6} width="100%" height={60} />

                    <Box textAlign="center">
                      <CircularProgress />
                    </Box>
                  </div>
                )}
                <div style={{ height: 20 }} />
              </div>
            </Grid>
            <Grid item xs={10} style={{ backgroundColor: " #E5E5E5" }}>
              <div style={{ padding: "20px 0px 40px 120px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <SearchInput
                    getUserData={props.getUserData}
                    getUserLinks={getUserLinks}
                  />
                  <div style={{ paddingRight: 95 }}>
                    <Button
                      variant="contained"
                      className="Admin_SelectButton"
                      sx={customStyles.SelectButton}
                      aria-controls="admin-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      style={{ textTransform: "none" }}
                    >
                      <Avatar {...stringAvatar(props.user.name)} />
                      <div style={{ width: 8 }} />
                      <Typography style={{ fontSize: 12, color: "#333333" }}>
                        {props.user.name}
                      </Typography>
                      <div style={{ width: 32 }} />
                      <AiFillCaretDown color="#333333" />
                    </Button>
                    <Menu
                      id="admin-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      style={{ top: 40 }}
                    >
                      <MenuItem
                        onClick={() => {
                          window.location.pathname = "/";
                          logOut();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div style={{ height: 16 }} />
                <Card className="Admin_lineChart" sx={customStyles.lineChart}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "24px 28px 0px 28px",
                    }}
                  >
                    <Typography
                      className="Admin_commonCss"
                      sx={customStyles.commonCss}
                      style={{ color: "#898989", fontSize: "14px" }}
                    >
                      Total Clicks/Visits
                    </Typography>
                    <Button
                      variant="contained"
                      className="Admin_containedButton"
                      sx={customStyles.containedButton}
                    >
                      Select Date
                    </Button>
                  </div>
                  <MyResponsiveLine />
                </Card>
                <div style={{ height: 16 }} />
                <div style={{ display: "flex" }}>
                  <Card className="Admin_pieChart" sx={customStyles.pieChart}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "24px 28px 0px 28px",
                      }}
                    >
                      <Typography
                        className="Admin_commonCss"
                        sx={customStyles.commonCss}
                        style={{ color: "#898989", fontSize: "14px" }}
                      >
                        Click Through Countries
                      </Typography>
                      <IconButton style={{ padding: 4 }}>
                        <BsThreeDotsVertical size={12} />
                      </IconButton>
                    </div>
                    <MyResponsivePie />
                  </Card>
                  <div style={{ width: 20 }} />
                  <Card className="Admin_barChart" sx={customStyles.barChart}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "24px 28px 0px 28px",
                      }}
                    >
                      <Typography
                        className="Admin_commonCss"
                        sx={customStyles.commonCss}
                        style={{ color: "#898989", fontSize: "14px" }}
                      >
                        Click Through Browser
                      </Typography>
                      <IconButton style={{ padding: 4 }}>
                        <BsThreeDotsVertical size={12} />
                      </IconButton>
                    </div>
                    <MyResponsiveBar />
                  </Card>
                </div>
              </div>
            </Grid>
            <ChatWidget
              title="Welcome to NeoLink"
              subtitle="Ask us anything in the chat window below 😊"
              primaryColor="#07AFD4"
              greeting=""
              newMessagePlaceholder="Start typing..."
              accountId={TEST_ACCOUNT_ID}
              baseUrl="https://app.papercups.io"
              customer={{
                name: props.user !== null ? props.user.name : null,
                email: props.user !== null ? props.user.email : null,
                phone: props.user !== null ? props.user.phoneNumber : null,
                // external_id: authState.loggedIn ? authState.user.uid : null,
                // metadata: {
                //     company: authState.loggedIn ? authState.user.adminData.company : null,
                //     portal: authState.loggedIn ? authState.user.adminData.portal : null,
                //     loggedIn: authState.loggedIn,
                // },
              }}
            />
          </Grid>
        )}
      </>
    );
}

export default (withRouter(Admin));

